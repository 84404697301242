import React from "react"
import SEO from "../components/seo"
import {Helmet} from "react-helmet"

import Boks from '../components/Boks'
import SomeBoks from '../components/someBoks'
import BoksHeader from '../components/BoksHeader'
import ProgressBoks from '../components/progressBoks'


import arre from '../images/arre3.jpg'
import wine from '../images/wine.svg'
import fire from '../images/drama.svg'
import hub from '../images/hub.svg'

import TimelineLi from '../components/timelineLi'


const Index = () => {

  return (
    <>

    <SEO title="Andreas Aarrestad | Sivilingeniørstudent og konsulent" description="Andreas Aarrestad er en 21-åring bosatt i Trondheim som driver med digital konsultering, webutvikling og programmering. "/>
    <div style={{position:"relative",width:"100%"}}>
      <div style={{position:"absolute",width:"100%"}}>
        <div className="background1">
          <nav aria-expanded="false" className="navbar navbar-expand-md navbar-default ">
            <button className="navbar-toggler"  data-toggle="collapse" data-target="#navbarResponsive">
               <i className="fas fa-bars" id="burger" style={{color:"#fff"}}></i>
            </button>

            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a className="nav-link" href="#ommeg"><b data-toggle="collapse" data-target="#navbarResponsive" className="navItem">Om meg</b></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#ferdigheter"><b data-toggle="collapse" data-target="#navbarResponsive" className="navItem">Ferdigheter</b></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#tidslinje"><b data-toggle="collapse" data-target="#navbarResponsive" className="navItem">Tidslinje</b></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#portefølje"><b data-toggle="collapse" data-target="#navbarResponsive" className="navItem">Portefølje</b></a>
                </li>


              </ul>


            </div>

          </nav>
          <div className="wrapper">
            <div id="row" className="row ">
              <div className="col-xs-12 col-sm-7 col-md-7 col-xl-7 ">
                <h1 id="navn">Andreas Aarrestad</h1>
                <h3>Sivilingeniørstudent og konsulent</h3>
              </div>
              <div id="some2" className="col-xs-12 col-sm-5 col-md-5 col-xl-5">
                <div className="some">

                  <SomeBoks navn="linkedin"/>

                  <SomeBoks navn="facebook"/>

                  <SomeBoks navn="instagram"/>
                  <SomeBoks navn="github"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="background2">
        </div>
        <div className="background3">
        </div>
      </div>
      <div className="boks1">

        <div id="ommeg"></div>
        <Boks>
          <BoksHeader headerName="Om meg">
            <div  className="row " style={{marginTop:"50px"}}>
              <div className="col">
                <img src={arre} className="arre float-left" alt="andreas"/>


                  <p>Jeg er en 21-åring fra Skien, nå bosatt i Trondheim. Her studerer jeg andreåret mot en mastergrad i
                   kommunikasjonsteknologi og digital sikkerhet ved NTNU Gløshaugen. Som student har jeg stor
                   interesse for både programmering og matematikk, men det er selve skjæringspunktet mellom teknologi, økonomi
                   og forretninger jeg brenner for. Fra studiene har jeg et godt grunnlag med emner som statistikk, calculus, fysikk og
                   algoritmekonstruksjon som jeg bygger på med emner som digital økonomi, anvendt nettverksteori og digital sikkerhet i IKT-systemer.<br/><br/>

                    Ved siden av
                    studiene har jeg og tre medstudenter også opprettet vårt eget konsulentselskap der vi driver med webutvikling og digital rådgivning.
                    Det har vært svært lærerikt og givende å få jobbe i team og få praktisk erfaring fra både det rent tekniske,
                    samt å lære å forholde seg til kunder og drive forretninger.<br/><br/>

                    Som person er jeg nøyaktig, utadvendt og arbeidsom, og ikke redd for nye utfordringer. Håper du som leser tar kontakt dersom det er noe
                    du tror jeg kan bidra med!



                    <br/><br/>


                  </p>

                </div>
            </div>
          </BoksHeader>
        </Boks>

        <div id="ferdigheter"></div>
        <Boks>
          <BoksHeader headerName="Ferdigheter">
            <div className=" contentDiv">


                <div className="row">



					<div className="col-xs-12 col-md-6 col-lg-3">
					  <ProgressBoks top="70px" header1="Utvikling" header2="programmering" width="90%" bilde="prog">
						Med emner som algoritmekonstruksjon og databaseutforming fikk jeg revolusjonert
						tilnærmingen til programmering og utvikling. Fra etableringen av selskapet vårt som fokuserer på webutvikling og digital rådgivning har jeg lært å strukturere
						arbeidet og jobbe godt i team. <br/><br/>
						Jeg har god erfaring med språk som JavaScript, Python, Java, SQL og PHP, samt bruk av rammeverk og bibliotek som React, jQuery, Gatsby og Bootstrap.


					  </ProgressBoks>
					</div>

					<div className="col-xs-12 col-md-6 col-lg-3">
						<ProgressBoks top="70px" header1="Nettverk" header2="sikkerhet" width="85%" bilde="sec">
							Mastergraden har et stort fokus på anvendt nettverksteori og informasjonssikkerhet. Her har jeg fått en avansert og utfyllende
							innføring i internettarkitekturen, som jeg har brukt til å programmere <a href="#lastfordeler">rutere</a> og andre nettverkskomponenter. Parallellt har jeg gode kunnskaper
							om reaksjonære og preventive tiltak mot eventuelle sikkerhetstrusler, og kraftige modeller for nettverksrobusthet. <br/><br/>





						</ProgressBoks>
					</div>

					<div className="col-xs-12 col-md-6 col-lg-3">
						 <ProgressBoks top="70px" header1="Økonomi" header2="analyse" width="65%" bilde="øko">
							Jeg har prosjekteringerfaring samt en teoretisk og praktisk bakgrunn for statistikkanalyser, trusselmodelleringer og risikoanalyser. I tillegg har
							jeg en grunnleggende forståelse for klassisk industriell økonomi, men også en spesiell kompetanse om den digitale økonomien. Den digitiale økonomien har brakt med
							seg nye
							forretningsmodeller som kan utnyttes med riktig kompetanse.




						</ProgressBoks>
					</div>

					<div className="col-xs-12 col-md-6 col-lg-3">
						<ProgressBoks top="70px" header1="Design" header2="kommunikasjon" width="55%" bilde="design">
							Med et godt øye for estetikk forstår jeg hvor avgjørende design av en tjeneste er når det kommer til brukeropplevelsen. I selskapet har jeg
							hatt en fremtredende rolle spesielt i designfasen, noe som har vært ekstremt lærerikt. I tillegg har jeg på enkelte oppdrag vært ansvarlig
							for kundekontakten, noe som har lært meg viktigheten av hvordan et budskap kommuniseres.



						</ProgressBoks>
					</div>




                </div>

            </div>
          </BoksHeader>
        </Boks>

        <div id="tidslinje"></div>
        <Boks>
          <BoksHeader headerName="Tidslinje">
          <section className="timeline">
            <ul>
              <TimelineLi year="HØST 2018" desc="Startet på masteren i kommunikasjonsteknologi og digital sikkerhet ved NTNU Gløshaugen"/>
              <TimelineLi year="VÅR 2019" desc="Frivillig læringsassistent for fem klasser i forbindelse med Vektorprogrammet"/>
              <TimelineLi year="SOMMER 2019" desc="Sommerpraktikant for Skagerak Energi ved Hogstad kraftverk i Siljan"/>
              <TimelineLi year="VÅR 2020" desc="Opprettet Leaf Consulting sammen med tre medstudenter"/>

            </ul>
          </section>

          </BoksHeader>
        </Boks>

      <div id="portefølje">
        <Boks>
          <BoksHeader headerName="Portefølje">
            <ul className="list-unstyled">

              <li className="media">
                <div className="row">
                  <div className="col-md-2 col-xs-12">
                    <img className=" porteføljeBilde porteføljeBilde2"  src={fire} style={{marginLeft:"10%",width:"80%"}}  alt="spellet"/>
                  </div>
                  <div className="col-md-10 col-xs-12">
                    <div className="media-body">
                      <div style={{clear:"both"}}>
                        <h2 style={{float:"left"}}>Østkantspellet</h2>

                        <p style={{fontSize:"24px",float:"right",marginBottom:"8px",paddingTop:"4px"}}><a href="https://spellet.no"><i className="porteføljeLink fas fa-external-link-alt"></i></a></p>
                        <br/><br/>
                      </div>
                      <span className="badge badge-warning porteføljeBadge " style={{color:"white"}}>JavaScript</span>
                      <span className="badge badge-info porteføljeBadge">React</span>
                      <span className="badge badge-success orange porteføljeBadge ">Sanity</span>
                      <span className="badge badge-info porteføljeBadge gatsby">Gatsby</span>

                      <div className="mt-2"><p>En statisk webside laget for Østkantspellet gjennom konsulentselskapet mitt.
                      Siden er kodet i React, og er videre utvidet med Gatsby-rammeverket for forhåndsinnlastning av sider og optimalisering
                      av bildeinnlastning. I tillegg brukes Gatsby sammen med publiseringssystemet Sanity for å bygge siden etter at
                      det er gjort endringer i koden. Fra den egne adminsiden der kunden med et brukervennlig grensesnitt kan publisere
                      innlegg på siden, henter Gatsby ut innleggene fra databasen med GraphQL og legger dem til i DOM-en som React-objekter.</p>



                      </div>
                    </div>
                  </div>
                </div>
              </li>

			  <div id="lastfordeler"></div>
              <li className="media">
                <div className="row">
                  <div className="col-md-2 col-xs-12">
                    <img className=" porteføljeBilde " src={hub} style={{width:"100%",marginLeft:"5%"}}  alt="lastfordeler"/>
                  </div>
                  <div className="col-md-10 col-xs-12">
                    <div className="media-body">

                    <div style={{clear:"both"}}>
                      <h2 style={{float:"left"}}>Lastfordeler</h2>
                      <p style={{fontSize:"24px",float:"right",marginBottom:"8px",paddingTop:"4px"}}><a href="https://github.com/Andreasaarrestad/loadbalancer"><i style={{marginLeft:"10px"}} className="porteføljeLink fab fa-github"></i></a></p>
                      <br/><br/>
                    </div>

                      <span className="badge badge-success porteføljeBadge " style={{backgroundColor:"#c92cbf"}}>Python</span>
                      <span className="badge badge-success  porteføljeBadge">OpenFlow</span>
                      <div className="mt-2">
                     <p> I forbindelse med en skoleoppgave for anvendt nettverksteori lagde jeg en lastfordeler for et simulert nettverk som ved hjelp av en round-robin-algoritme viderekobler
                      eventuelle forespørsler til en webtjeneste til en av mange tilgjengelige backend-servere. For å oppnå
                      en software-definert nettverksstruktur (SDN), brukes en kontroller til å styre lastfordeleren med
                      kommunikasjonsprotokollen OpenFlow. <br/><br/>

                      Lastfordeleren fungerer også som en proxy for backend-serverne ved å spoofe serveren sin MAC adresse til sin egen
                      når serveren svarer på ARP-forespørselen til klienten slik at hele lastfordelingen er usynlig for klienten.
</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>

              <li className="media">
                <div className="row">
                  <div className="col-md-2 col-xs-12">
                    <img className=" porteføljeBilde"  src={wine} style={{width:"100%"}}  alt="vindatabase"/>
                  </div>
                  <div className="col-md-10 col-xs-12">
                    <div className="media-body">
                      <div style={{clear:"both"}}>
                        <h2 style={{float:"left"}}>Vindatabase</h2>
                        <p style={{fontSize:"24px",float:"right",marginBottom:"8px",paddingTop:"4px"}}><a href="http://35.228.242.86/wine2/index.php"><i className="porteføljeLink fas fa-external-link-alt"></i></a><a href="https://github.com/Andreasaarrestad/vindatabase"><i style={{marginLeft:"10px"}} className="porteføljeLink fab fa-github"></i></a></p>
                        <br/><br/>
                      </div>

                        <span className="badge badge-warning porteføljeBadge " style={{color:"white"}}>JavaScript</span>
                          <span className="badge badge-danger porteføljeBadge">PHP</span>
                      <span className="badge badge-primary porteføljeBadge">SQL</span>


                      <div className="mt-2">
                      <p>Egetmotivert prosjekt bygget med LAMP-stacken for å lage en database som kan gi en oversikt over ens vinbeholdning.
                      En vin legges
                      til i beholdningen ved å skrive inn vinens varenummer fra vinmonopolet sin database
                      og sender en HTTP-forespørsel
                       med varenummeret
                      som parameter til en av polets API-er, som returnerer et JSON-objekt med all informasjon om den gitte vinen.
                      Enkelte av disse feltene blir lagt til i min egen database, der vinobjektet blir lagret sammen med brukerens brukerID. Etterpå kan brukeren
                      i et eget vindu redigere antall viner, skrive en liten anmeldelse og gi en rangering på null til ti stjerner.<br/><br/>
                      Jeg lagde også en simpel innloggingsside for tjenesten med brukernavn og passord (som riktignok ikke er hasha) slik at de forskjellige brukerne ikke kan se hverandres vinkjellere.
</p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>


            </ul>
            </BoksHeader>
          </Boks>
        </div>
        <div style={{textAlign:"right",float:"right",marginBottom:"30px",paddingRight:"5%",fontSize:"8px",color:"#B0B0B0"}}>
          Icons made by <a href="https://www.flaticon.com/authors/srip" title="srip" style= {{color:"#B0B0B0"}}>srip</a> from <a href="https://www.flaticon.com/" title="Flaticon" style= {{color:"#B0B0B0"}}>www.flaticon.com</a>
          <br/>Icons made by <a href="https://www.flaticon.com/authors/nikita-golubev" style= {{color:"#B0B0B0"}} title="Nikita Golubev">Nikita Golubev</a> from <a href="https://www.flaticon.com/" title="Flaticon " style= {{color:"#B0B0B0"}}>www.flaticon.com</a>
          <br/>Icons made by <a href="https://www.flaticon.com/authors/ultimatearm" title="ultimatearm" style= {{color:"#B0B0B0"}} >ultimatearm</a> from <a href="https://www.flaticon.com/" title="Flaticon " style= {{color:"#B0B0B0"}}>www.flaticon.com</a>
          <br/>Icons made by Icons made by <a href="https://www.flaticon.com/authors/freepik" style= {{color:"#B0B0B0"}} title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon " style= {{color:"#B0B0B0"}}>www.flaticon.com</a>

        </div>

      </div>
    </div>




    <Helmet>

      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
      <script src="https://code.jquery.com/jquery-3.4.1.slim.min.js" integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js" integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo" crossorigin="anonymous"></script>
<script src="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/js/bootstrap.min.js" integrity="sha384-wfSDF2E50Y2D1uUdj0O3uMBJnjuUD4Ih7YwaYd1iqfktj0Uod8GCExl3Og8ifwB6" crossorigin="anonymous"></script>
      <script src="https://kit.fontawesome.com/ccac5df2b0.js" crossorigin="anonymous"></script>

    </Helmet>


    </>
  )
}

export default Index
